@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'Vazirmatn';
  src: local("Vazirmatn"), url(./Fonts/Vazirmatn-FD-ExtraBold.woff2) format("woff");
}

@font-face {
  font-family: 'Vazirmatn';
  src: local("Vazirmatn"), url(./Fonts/Vazirmatn-FD-Black.woff2) format("woff");
}

@font-face {
  font-family: 'Vazirmatn';
  src: local("Vazirmatn"), url(./Fonts/Vazirmatn-FD-Light.woff2) format("woff");
}

@font-face {
  font-family: 'Vazirmatn';
  src: local("Vazirmatn"), url(./Fonts/vazirmatnregular.ttf) format("woff");
}

@font-face {
  font-family: 'Vazirmatn';
  src: local("Vazirmatn"), url(./Fonts/Vazirmatn-FD-ExtraLight.woff2) format("woff");
}
@font-face {
  font-family: 'Vazirmatn';
  src: local("Vazirmatn"), url(./Fonts/Vazirmatn-FD-Bold.woff2) format("woff");
}


body {
  fontFamily: 'Vazirmatn';
  background-color: #F0F3F7;
  direction: rtl;
  overflow: auto;
  overflow-x: hidden;
}

* {
  font-family: 'Vazirmatn';
}

#root {
  height: 100vh;
}

@media only screen and (max-width: 768px) {
  body {
    background-color: white;
  }
}

#messageMenu::-webkit-scrollbar {
  width: 0;
  display: none;
}

.wm-container a,
.water-mark-img {
  display: none;
}

#backPage-strip {
  width: 0px !important;
  text-align: center;
  justify-items: center;
  transition: 0.4s !important;
  overflow: hidden;
}

#backPage:hover #backPage-strip {
  display: block;
  width: 90px !important;
  transition: 0.4s !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

#fullmapTitle {
  width: 0px !important;
  text-align: center;
  justify-items: center;
  transition: 0.4s !important;
  text-wrap: nowrap;
  overflow: hidden;
}

#fullmapBtn:hover #fullmapTitle {
  display: block;
  width: 90px !important;
  transition: 0.4s !important;
}
/* 
@keyframes scrollAnime {
  from {
    opacity: 0;
    transform: translateY(-100%);
    clip-path: inset(100% 0 0 0);
    transition-duration: 1s;
  }

  to {
    opacity: 1;
    transform: translateY(0);
    transition-duration: 1s;
    clip-path: inset(0 0 0 0);
  }
}

.hiddenAnime {
  animation: scrollAnime linear;
  animation-timeline: view();
  animation-range: entry 0% cover 30%;
}


@keyframes leftToRight {
  from {
    opacity: 0;
    transform: translateX(-100%);
    transition-duration: 1s;
    filter: blur(5px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
    transition-duration: 1s;
    filter: blur(0);
  }
}

@keyframes leftToRight2 {
  from {
    opacity: 0;
    transition-duration: 1s;
    transform: translateX(-400%);
    filter: blur(5px);
    animation-delay: 1s;
  }

  to {
    opacity: 1;
    transition-duration: 1s;
    transform: translateX(0);
    filter: blur(0);
    animation-delay: 1s;
  }
}

.hiddenAnimeLeftToRight2 {
  animation: leftToRight2 linear;
  animation-timeline: view();
  animation-range: entry 0% cover 30%;
}

.hiddenAnimeLeftToRight {
  animation: leftToRight linear;
  animation-timeline: view();
  animation-range: entry 0% cover 30%;
} */