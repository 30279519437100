.input-container {
    height: 48px;
    display: flex;
    border-radius: 10px;
    border: 1px solid rgba(187, 187, 187, 1);
}

.active-input-container{
    width: 15%;
    height: 48px;
    display: flex;
    border-radius: 10px;
    border: 1px solid rgb(56, 56, 56);
}



@media (max-width: 768px) {
    .input-container {
        width: 90%;
        height: 48px;
    }
    .active-input-container{
        width: 90%;
        height: 48px;
        display: flex;
        border-radius: 10px;
        border: 1px solid rgb(56, 56, 56);
    }
}
@media (max-width: 1280px ) and (min-width: 800px){
    .input-container {
        width: 60%;
        height: 48px;
    }
    .input-container-forSelectCar{
        width: 50%;
        height: 48px;
        border: 1px solid rgba(187, 187, 187, 1);
        display: flex;
        border-radius: 10px;
        color: rgba(168, 180, 191, 1);
    }
}
.input-Icon{
    color: #A8B4BF;
    font-size: 24px;
    margin-top: 12px;
    margin-right: 12px;
    margin-left: 12px;

}
.input-container-counter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 160px;
    height: 48px;
    border: 1px solid rgba(187, 187, 187, 1);
    border-radius: 10px;
    padding: 0 10px;
}
.input-container-forSelectCar{
    width: 160px;
    height: 48px;
    border: 1px solid rgba(187, 187, 187, 1);
    display: flex;
    border-radius: 10px;
    color: rgba(168, 180, 191, 1);
}

@media (min-width: 768px) {
    .Handle{
        display: none;
    }
}

.CityName {
    font-Size: 16px;
    margin-Right: 8px;
    font-Weight: 600;
}



.rmdp-container {
    height: 100% !important;
}
.rmdp-input:focus{
    border-color: transparent !important;
    box-shadow: none !important;
}

.rmdp-wrapper .rmdp-shadow{
    width: 100% !important;
}


.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
    background-color: bisque;
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
    border-top: 1px solid bisque;
}

.rmdp-container .custom-calendar.ep-arrow[direction="top"] {
    border-bottom: 1px solid bisque;
}

