.form_input {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #aeaeae;
    border-radius: 0.5rem;
    font-family: IranSans;
    font-size: 20px;
    color: rgba(16, 14, 52, 1);
    outline: none;
    padding: 8px 4px 4px 4px;
    background: none;
    direction: rtl;
}

.form_input:hover {
    border-color: rgba(0, 0, 0, 0.5);
    color: rgba(16, 14, 52, 1);
}

.form_input:focus {
    border-color: rgba(0, 0, 0, 0.5);
}

.form_label {
    font-family: IranSans;
    font-size: 20px;
    position: absolute;
    right: 1rem;
    top: 0.8rem;
    color: white;
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
    background-color: white;
    color: rgba(187, 187, 187, 1);
}

.form_input:focus ~ .form_label,
.form_input:not(:placeholder-shown).form_input:not(:focus) ~ .form_label {
    top: -0.7rem;
    font-size: 16px;
    right: 0.8rem;
    color: rgba(0, 0, 0, 0.5);
}


@media (max-width: 768px) {
    .form_input {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 44px;
        border: 2px solid #aeaeae;
        border-radius: 0.5rem;
        font-family: IranSans;
        font-size: 16px;
        color: rgba(16, 14, 52, 1);
        outline: none;
        padding: 8px 4px 4px 4px;
        background: none;
        direction: rtl;
    }

    .form_label {
        font-family: IranSans;
        font-size: 16px;
        position: absolute;
        right: 1rem;
        top: 0.6rem;
        color: white;
        cursor: text;
        transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
        background-color: white;
        color: rgba(187, 187, 187, 1);
    }

    .form_input:focus ~ .form_label,
    .form_input:not(:placeholder-shown).form_input:not(:focus) ~ .form_label {
        top: -0.7rem;
        font-size: 16px;
        right: 0.8rem;
        color: rgba(0, 0, 0, 0.5);
    }
}

.rmdp-input::placeholder {
    color: black !important;
    margin-right: 8px !important;
}