.LoginInput {
    background-color: transparent;
    width: 100%;
    outline: none;
    height: 100%;
    font-size: 20px;
    margin-right: 16px;
}

.EyeIcon {
    color: #414141;
    font-size: 32px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .LoginInput {
        font-size: 16px;
        margin-right: 12px;
    }

    .EyeIcon {
        color: #414141;
        font-size: 24px;
        cursor: pointer;
        margin-left: 4px;
    }
    .form{
        max-width:100%;
    }
    .form__input{
        max-width:100%;
        font-size: 16px !important;
        height: 80% !important;
    }
    .form__input:focus ~ .form__label,
    .form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
        right: 1.2rem !important;
        top: -0.7rem !important;
    }
    .form__label{
        right: 1.2rem !important;
        font-size: 16px !important;
        top: 0.5rem !important; ;
    }
    .inputDiv{
        margin-bottom: 16px !important;
    }
}

.form {
    width: 80%;
    height:100%;
    position: relative;
}

.form__input {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #e1e5ee;
    border-radius: 0.5rem;
    font-family: IranSans;
    font-size: 18px;
    color: rgba(16, 14, 52, 1);
    outline: none;
    padding: 8px 4px 4px 4px;
    background: none;
    direction: rtl;
}

.form__input:hover {
    border-color: rgba(0, 0, 0, 0.5);
    color: rgba(16, 14, 52, 1);
}

.form__input:focus {
    border-color: rgba(0, 0, 0, 0.5);
}

.form__label {
    font-family: IranSans;
    font-size: 18px;
    position: absolute;
    right: 1rem;
    top: 0.8rem;
    color: white;
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
    background-color: white;
    color: rgba(187, 187, 187, 1);
}

.form__input:focus ~ .form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
    top: -0.7rem;
    font-size: 16px;
    right: 0.8rem;
    color: rgba(0, 0, 0, 0.5);
}

.input-filled {
    border: 2px solid #100E34;
}

.inputDiv{
    position: relative;
    margin-bottom: 30px;
    width: 80%;
    height: 52px;
}


.rmdp-container {
    width: 100% !important;
}


.fixed-code {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: gray;
    border-right: 2px solid gray;
    padding-right: 0.5rem;
    z-index: 10;
}

.form__input {
    padding-left: 3rem;
}

@media (max-width: 768px) {
    .fixed-code {
        display: flex;
        align-items: center;
        position: absolute;
        left: 1rem;
        top: 1.4rem;
        transform: translateY(-50%);
        font-size: 16px;
        color: gray;
        padding-right: 0.5rem;
        z-index: 10;
    }
}