.Header {
    width: 100%;
    height: 380px;
    background-size: cover;
    background-repeat: no-repeat;
    direction: rtl;
}

.HeaderList {
    display: flex;
    gap: 26px;
    color: white;
    font-size: 16px;
    margin-right: 28px;
}

.HeaderIcons {
    width: 24px;
    height: 24px;
}


@keyframes slideDown {
    from {

    }
    to {
    }
}

.animated {
    animation-name: slideDown;
    animation-duration: 0.5s;
}


.ant-modal:where(.css-dev-only-do-not-override-mzwlov).ant-modal {
    top: initial;
    width: fit-content !important;
    height: fit-content !important;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.61);
    position: fixed !important;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-modal .ant-modal-content {
    padding: 0px !important;
    width: auto;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-modal-root .ant-modal-wrap {
    display: flex;
    justify-content: center;
    padding-bottom: 0px !important;
    align-items: center !important;
}

.ant-modal:where(.css-dev-only-do-not-override-mzwlov).ant-modal {
    padding-bottom: 0px !important;
}

@media only screen and (max-width: 768px) {
    .ant-modal:where(.css-dev-only-do-not-override-mzwlov).ant-modal {
        width: 95% !important;
    }
}

:where(.css-dev-only-do-not-override-mzwlov).ant-modal-root .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.86) !important;
    backdrop-filter: blur(2px);
    box-shadow: 9px -8px 11px 0px rgba(255, 255, 255, 1);
}


@keyframes slideDown {
    from {
        transform: translateY(-25%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-down {
    animation: slideDown 250ms ease-out forwards;
}

@keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-25%);
        opacity: 0;
    }
}

.slide-up {
    animation: slideUp 250ms ease-out forwards;
}


.custom-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    max-width: 500px;
    width: 100%;
}

.custom-modal-close {
    position: absolute;
    top: 0.4rem ;
    right: 1rem;
    background: none;
    border: none;
    font-size: 32px;
    cursor: pointer;
    z-index: 200;
    color: #082F78;
    width: fit-content;
    height: fit-content;
}

@media only screen and (max-width: 768px) {
    .modalMobile {
        width: 95%;
    }
    .custom-modal-close {
        position: absolute;
        top: 0.8rem ;
        right: 0.8rem;
    }
}

 .ReactModal__Overlay--after-open{
    z-index: 100;
}

.no-select {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
